import React from 'react';
import { Router } from '@reach/router';

import {
  PlanRouter,
  StepRedirect,
  StepSelectorRedirect,
} from '../components/router';
import { EtapaLayout } from '../layouts';

const EtapaPage = () => {
  return (
    <Router basepath="/etapa">
      <StepRedirect path="/" />
      <StepSelectorRedirect path="/:stepId" />
      <PlanRouter path="/:stepId/tarea/:taskId" component={EtapaLayout} />
    </Router>
  );
};

export default EtapaPage;
